import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import Card from "@mui/material/Card";
import RivalzApi from "../../../config/axios/RivalzApi";
import { useQueryClient } from "@tanstack/react-query";
import useConnection from "../../../config/zustand";
import toast from "react-hot-toast";

function GenerateEncryptKey() {
  const { setBackdrop } = useConnection();
  const queryClient = useQueryClient();

  const generateEncryptKey = async () => {
    try {
      setBackdrop(true);
      await RivalzApi.createEncryptKey();
      await queryClient.refetchQueries({ queryKey: ["encryptedKeyStatus"] });
      toast.success("Encrypted Api Key generated successfully");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Card id={"api"}>
      <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Create Encrypted Key
        </MDTypography>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox>
            <MDButton variant="gradient" color="dark" onClick={generateEncryptKey}>
              Create Encrypted Key
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}
export default GenerateEncryptKey;
