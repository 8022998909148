import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { mainnet, polygon, optimism, arbitrum, base } from "wagmi/chains";

const config = getDefaultConfig({
  appName: "Rivalz Dashboard dev",
  projectId: "rivalz-dashboard-dev",
  chains: [mainnet, polygon, optimism, arbitrum, base],
  ssr: true,
});

export default config;
