import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useQuery } from "@tanstack/react-query";
import RivalzApi from "../../../../config/axios/RivalzApi";
import Invoice from "../Invoice";
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";

function Invoices() {
  const pageSize = 7;
  const [page, setPage] = useState(1);

  const { data: invoices, isSuccess } = useQuery({
    queryKey: ["invoices"],
    queryFn: () => RivalzApi.getInvoicesHistory(),
    select: (data) => data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  });

  return (
    <Card sx={{ height: "100%", justifyContent: "space-between" }}>
      <MDBox>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium">
            Invoices
          </MDTypography>
        </MDBox>
        <MDBox p={2} justifyContent="space-between">
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {isSuccess &&
              invoices
                ?.slice((page - 1) * pageSize, page * pageSize)
                ?.map((invoice) => <Invoice key={invoice._id} invoice={invoice} />)}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="center" pb={2}>
        <MDBox p={2}>
          <Pagination
            count={Math.ceil(invoices?.length / pageSize)}
            onChange={(e, page) => setPage(page)}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Invoices;
