import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import GenerateApiKey from "./components/GenerateApiKey";
import GenerateEncryptKey from "./components/GenerateEncryptKey";
import useConnection from "../../config/zustand";
import StripeProducts from "./components/StripeProducts";

function Api() {
  const { generatedEncryptKey } = useConnection();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={generatedEncryptKey ? 12 : 6}>
              <GenerateApiKey />
            </Grid>
            {!generatedEncryptKey && (
              <Grid item xs={12} md={6}>
                <GenerateEncryptKey />
              </Grid>
            )}
            <Grid item xs={12} md={generatedEncryptKey ? 12 : 6}>
              <StripeProducts />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Api;
