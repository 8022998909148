import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import pattern from "assets/images/illustrations/pattern-tree.svg";
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogoWhite from "../../../assets/images/logos/visa-white.png";
import amexLogo from "../../../assets/images/logos/amex.png";
import discoverLogo from "../../../assets/images/logos/discover.png";
import dinnersLogo from "../../../assets/images/logos/diners-club.png";
import jcbLogo from "../../../assets/images/logos/jcb.png";
import unionpayLogo from "../../../assets/images/logos/union-pay.png";

function MasterCard({ color, number, expires, type }) {
  const numbers = [...`${number}`];

  const num1 = numbers.slice(0, 4).join("");
  const num2 = numbers.slice(4, 8).join("");
  const num3 = numbers.slice(8, 12).join("");
  const num4 = numbers.slice(12, 16).join("");

  console.log(type);

  return (
    <Card
      sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
        background: gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.dark.main, gradients.dark.state),
        boxShadow: xl,
        position: "relative",
      })}
    >
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.2}
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
        }}
      />
      <MDBox position="relative" zIndex={2} p={2}>
        <MDBox color="white" p={1} lineHeight={0} display="inline-block">
          <Icon fontSize="default">wifi</Icon>
        </MDBox>
        <MDTypography variant="h5" color="white" fontWeight="medium" sx={{ mt: 3, mb: 5, pb: 1 }}>
          {num1}&nbsp;&nbsp;&nbsp;{num2}&nbsp;&nbsp;&nbsp;{num3}&nbsp;&nbsp;&nbsp;{num4}
        </MDTypography>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Expires
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {expires}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" width="20%">
            <MDBox
              component="img"
              src={
                type === "visa"
                  ? visaLogoWhite
                  : type === "mastercard"
                  ? masterCardLogo
                  : type === "amex"
                  ? amexLogo
                  : type === "discover"
                  ? discoverLogo
                  : type === "diners"
                  ? dinnersLogo
                  : type === "jcb"
                  ? jcbLogo
                  : type === "unionpay"
                  ? unionpayLogo
                  : masterCardLogo
              }
              alt="master card"
              width="auto"
              height="90px"
              mt={1}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of MasterCard
MasterCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the MasterCard
MasterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  number: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MasterCard;
