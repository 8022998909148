import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import backgroundImage from "assets/images/bg-profile.jpeg";
import useConnection from "../../../../config/zustand";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import RivalzApi from "../../../../config/axios/RivalzApi";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

function Header({ children }) {
  const { customer, creditInfo, setBackdrop } = useConnection();
  const queryClient = useQueryClient();

  const handleGetCredit = async () => {
    try {
      setBackdrop(true);
      await RivalzApi.createCredit();
      await queryClient.refetchQueries({ queryKey: ["creditInfo"] });
      toast.success("Get credit successfully");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDBox
              width={80}
              height={80}
              borderRadius="section"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MDTypography variant="h1" fontWeight="medium">
                {customer?.name.charAt(0)}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {customer?.name}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Button
                fullWidth={true}
                disabled={creditInfo}
                variant="outlined"
                color={"info"}
                style={{ color: "black" }}
                onClick={handleGetCredit}
              >
                Get Credit
              </Button>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
