import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import config from "config/RainBowConfig";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const container = document.getElementById("app");
const root = createRoot(container);

const queryClient = new QueryClient();
const stripePromise = loadStripe(
  "pk_test_51Pq3kODZ6fW9IKd83CzxlHY0KcFHaZa1pVmQUnBWbU2Mm10z8pDRXNnG8f9HoQP7qvEvkquZUEXumjATeki175z100bQBgLNEE"
);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
