import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MasterCard from "examples/Cards/MasterCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import RivalzApi from "../../config/axios/RivalzApi";
import { useEffect, useState } from "react";
import useConnection from "../../config/zustand";
import TextField from "@mui/material/TextField";
import MDTypography from "../../components/MDTypography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import toast from "react-hot-toast";

function Billing() {
  const { customer, setBackdrop } = useConnection();
  const queryClient = useQueryClient();
  const [defaultPayment, setDefaultPayment] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const { data: billingData, isSuccess: fetchBillingSs } = useQuery({
    queryKey: ["billing"],
    queryFn: async () => RivalzApi.getPaymentMethods(),
  });

  useEffect(() => {
    if (fetchBillingSs) {
      const defaultPaymentMethod = billingData?.paymentMethods?.find(
        (payment) => payment?.id === billingData?.defaultPaymentMethod
      );
      setDefaultPayment(defaultPaymentMethod);
    }
  }, [billingData]);

  const handleCreatePaymentInfo = async () => {
    try {
      setBackdrop(true);
      if (!email || !name) {
        throw new Error("Email and name are required");
      }
      await RivalzApi.createPaymentInfo({
        email,
        name,
      });
      await queryClient.refetchQueries({ queryKey: ["customer"] });
      toast.success("Payment info created successfully");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {customer ? (
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={12}>
                    <MasterCard
                      number={`************${defaultPayment?.card?.last4 || "****"}`}
                      expires={`${defaultPayment?.card?.exp_month || ""}/${
                        defaultPayment?.card?.exp_year || ""
                      }`}
                      type={defaultPayment?.card?.brand}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PaymentMethod
                      paymentMethods={billingData?.paymentMethods}
                      defaultPaymentMethod={billingData?.defaultPaymentMethod}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Invoices />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <BillingInformation />
              </Grid>
              <Grid item xs={12} md={5}>
                <Transactions />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox mt={8}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Card id="add-paymnet-info">
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Add Payment Info
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        type={"email"}
                        label="Enter email"
                        variant="outlined"
                        fullWidth={true}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Enter name"
                        variant="outlined"
                        fullWidth={true}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={3} mb={2}>
                    <Button fullWidth={true} variant="outlined" onClick={handleCreatePaymentInfo}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Create Payment Info
                      </MDTypography>
                    </Button>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Billing;
