import { create } from "zustand";

const useConnection = create((set) => ({
  jwt: localStorage.getItem("jwtToken") || null,
  customer: null,
  creditInfo: null,
  userInfo: null,
  generatedEncryptKey: true,
  backdrop: false,
  setJwt: (jwt) => set({ jwt }),
  setCustomer: (customer) => set({ customer }),
  setCreditInfo: (creditInfo) => set({ creditInfo }),
  setUserInfo: (userInfo) => set({ userInfo }),
  setGeneratedEncryptKey: (generatedEncryptKey) => set({ generatedEncryptKey }),
  setBackdrop: (backdrop) => set({ backdrop }),
}));

export default useConnection;
