import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { FormControl, InputAdornment, InputLabel, OutlinedInput, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import RivalzApi from "../../../config/axios/RivalzApi";
import useConnection from "../../../config/zustand";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

function GenerateApiKey() {
  const { userInfo, setBackdrop } = useConnection();
  const queryClient = useQueryClient();
  const [age, setAge] = useState("1d");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const generateApiKey = async () => {
    try {
      setBackdrop(true);
      if (!age) {
        throw new Error("Please select expire time");
      }
      await RivalzApi.createSecretApiKey({ exp: age });
      await queryClient.refetchQueries({ queryKey: ["userInfo"] });
      toast.success("Api Key Generated Successfully");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBackdrop(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(userInfo?.secretApiKey);
    toast.success("Api Key Copied to Clipboard");
  };

  return (
    <Card id={"api"}>
      <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Generate Api Key
        </MDTypography>
        <MDBox display="flex" gap={1} justifyContent="space-between" alignItems="center">
          <MDBox>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Expire time</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={age}
                label="Expire time"
                onChange={handleChange}
                sx={{ width: "150px", height: "40px" }}
              >
                <MenuItem value="1d">One Day</MenuItem>
                <MenuItem value="1w">One Week</MenuItem>
                <MenuItem value="1m">One Month</MenuItem>
                <MenuItem value="1y">One Year</MenuItem>
              </Select>
            </FormControl>
          </MDBox>
          <MDBox>
            <MDButton variant="gradient" color="dark" onClick={generateApiKey}>
              Generate Api Key
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox px={2} pb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userInfo?.secretApiKey && (
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" onClick={copyToClipboard}>
                  Api key
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={userInfo?.secretApiKey}
                  disabled={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Api key"
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default GenerateApiKey;
