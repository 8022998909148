import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Bill from "layouts/billing/components/Bill";
import useConnection from "../../../../config/zustand";

function BillingInformation() {
  const { customer } = useConnection();

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Billing Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {customer && (
            <Bill
              name={customer?.name}
              email={customer?.email}
              id={customer?.customerId}
              noGutter
            />
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
