import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import RivalzApi from "../../../../config/axios/RivalzApi";
import { useQueryClient } from "@tanstack/react-query";
import useConnection from "../../../../config/zustand";
import toast from "react-hot-toast";

function Transaction({ sub }) {
  const { setBackdrop } = useConnection();
  const queryClient = useQueryClient();

  const handleUnsubscribe = async () => {
    try {
      setBackdrop(true);
      await RivalzApi.unsubscribeSubscription({ subscription_id: sub?.subscriptionId });
      await queryClient.invalidateQueries("subscription");
      toast.success("Unsubscribed successfully");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <MDBox key={sub?._id} component="li" py={1} pr={2} mb={1}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton
              variant="outlined"
              color={sub?.status === "active" ? "success" : "error"}
              iconOnly
              circular
            >
              <Icon sx={{ fontWeight: "bold" }}>
                {sub?.status === "active" ? "done" : "canceled"}
              </Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="medium" gutterBottom>
              {sub?.["product"]?.name}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {moment(sub?.createdAt).format("DD MMM YYYY, hh:mm A")}
            </MDTypography>
          </MDBox>
        </MDBox>
        {sub?.status === "active" && (
          <MDBox
            onClick={handleUnsubscribe}
            display="flex"
            alignItems="center"
            lineHeight={1}
            ml={3}
            sx={{ cursor: "pointer" }}
          >
            <Icon fontSize="small" color={"error"}>
              delete
            </Icon>
            <MDTypography variant="button" color={"error"} fontWeight="bold">
              Un Subscribed
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  sub: PropTypes.any.isRequired,
};

export default Transaction;
