// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import useConnection from "../../../../config/zustand";
import { useQuery } from "@tanstack/react-query";
import RivalzApi from "../../../../config/axios/RivalzApi";

function PlatformSettings() {
  const { customer } = useConnection();

  const { data: detailUploaded, isSuccess } = useQuery({
    queryKey: ["detailUploaded"],
    queryFn: async () => RivalzApi.getDetailUploaded(),
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          user info
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Email:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {customer?.email}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Name:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {customer?.name}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            application
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  File Uploads:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {detailUploaded?.totalFileUploaded}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  GB uploaded:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {detailUploaded?.totalGbUploaded?.toFixed(2)}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
