import axiosClient from "./AxiosClient";

const RivalzApi = {
  login: async (data) => {
    const url = "/api-v1/auth/login";
    return axiosClient.post(url, data);
  },
  register: async (data) => {
    const url = "/api-v1/auth/register";
    return axiosClient.post(url, data);
  },
  getInvoicesHistory: async () => {
    const url = "/api-v1/stripe/invoices-history";
    return axiosClient.get(url);
  },
  getSubscription: async () => {
    const url = "/api-v1/stripe/subscriptions";
    return axiosClient.get(url);
  },
  getPaymentMethods: async () => {
    const url = "/api-v1/stripe/payment-methods";
    return axiosClient.get(url);
  },
  unsubscribeSubscription: async (data) => {
    const url = "/api-v1/stripe/unsubscribe-subscription";
    return axiosClient.post(url, data);
  },
  addPaymentMethod: async (data) => {
    const url = "/api-v1/stripe/add-payment-method";
    return axiosClient.post(url, data);
  },
  setDefaultPaymentMethod: async (data) => {
    const url = "/api-v1/stripe/set-default-payment-method";
    return axiosClient.post(url, data);
  },
  getCustomer: async () => {
    const url = "/api-v1/stripe/customer";
    return axiosClient.get(url);
  },
  createPaymentInfo: async (data) => {
    const url = "/api-v1/stripe/create-stripe-customer";
    return axiosClient.post(url, data);
  },
  getDetailUploaded: async () => {
    const url = "/api-v1/upload-history/detail-uploaded";
    return axiosClient.get(url);
  },
  getChartData: async (type) => {
    const url = `/api-v1/upload-history/chart-data?type=${type}`;
    return axiosClient.get(url);
  },
  getCreditInfo: async () => {
    const url = "/api-v1/stripe/credit";
    return axiosClient.get(url);
  },
  createCredit: async () => {
    const url = "/api-v1/stripe/credit";
    return axiosClient.post(url);
  },
  getUserInfo: async () => {
    const url = "/api-v1/auth/me";
    return axiosClient.get(url);
  },
  createSecretApiKey: async (data) => {
    const url = "/api-v1/auth/create-secret-api-key";
    return axiosClient.post(url, data);
  },
  createEncryptKey: async () => {
    const url = "/api-v1/auth/create-secret-key";
    return axiosClient.post(url);
  },
  getEncryptKeyStatus: async () => {
    const url = "/api-v1/auth/encrypt-crated-status";
    return axiosClient.get(url);
  },
  getProducts: async () => {
    const url = "/api-v1/stripe/products";
    return axiosClient.get(url);
  },
  subscription: async (data) => {
    const url = "/api-v1/stripe/create-subscription";
    return axiosClient.post(url, data);
  },
};

export default RivalzApi;
