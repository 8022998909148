import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import PropTypes from "prop-types";
import { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import RivalzApi from "../../../../config/axios/RivalzApi";
import { useQueryClient } from "@tanstack/react-query";
import Tooltip from "@mui/material/Tooltip";
import useConnection from "../../../../config/zustand";
import toast from "react-hot-toast";
import amexLogo from "../../../../assets/images/logos/amex.png";
import discoverLogo from "../../../../assets/images/logos/discover.png";
import dinnersLogo from "../../../../assets/images/logos/diners-club.png";
import jcbLogo from "../../../../assets/images/logos/jcb.png";
import unionpayLogo from "../../../../assets/images/logos/union-pay.png";

function PaymentMethod({ paymentMethods, defaultPaymentMethod }) {
  const { setBackdrop } = useConnection();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    try {
      setBackdrop(true);
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      await RivalzApi.addPaymentMethod({ payment_method_id: paymentMethod.id });
      await queryClient.refetchQueries({ queryKey: ["billing"] });
      toast.success("Payment method added successfully");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setBackdrop(false);
      setOpen(false);
    }
  };

  const setDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      setBackdrop(true);
      if (paymentMethodId === defaultPaymentMethod) {
        throw new Error("This is already your default payment method");
      }
      await RivalzApi.setDefaultPaymentMethod({ payment_method_id: paymentMethodId });
      await queryClient.refetchQueries({ queryKey: ["billing"] });
      toast.success("Default payment method set successfully");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Card id="delete-account">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Payment Method
        </MDTypography>
        <MDButton variant="gradient" color="dark" onClick={() => setOpen(!open)}>
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;add new card
        </MDButton>
      </MDBox>
      {open && (
        <MDBox p={2}>
          <form onSubmit={handleSubmit}>
            <CardElement />
            <MDBox mt={3} mb={2}>
              <Button fullWidth={true} disabled={!stripe} type={"submit"} variant="outlined">
                <MDTypography variant="h6" fontWeight="medium">
                  Add Payment Method
                </MDTypography>
              </Button>
            </MDBox>
          </form>
        </MDBox>
      )}
      <MDBox p={2}>
        <Grid container spacing={3}>
          {paymentMethods?.map((payment) => (
            <Grid key={payment?.id} item xs={12} md={6}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox
                  component="img"
                  src={
                    payment?.card?.brand === "visa"
                      ? visaLogo
                      : payment?.card?.brand === "mastercard"
                      ? masterCardLogo
                      : payment?.card?.brand === "amex"
                      ? amexLogo
                      : payment?.card?.brand === "discover"
                      ? discoverLogo
                      : payment?.card?.brand === "diners"
                      ? dinnersLogo
                      : payment?.card?.brand === "jcb"
                      ? jcbLogo
                      : payment?.card?.brand === "unionpay"
                      ? unionpayLogo
                      : masterCardLogo
                  }
                  alt="master card"
                  width="10%"
                  height="40px"
                  mr={2}
                />
                <MDTypography variant="h6" fontWeight="medium">
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{payment?.card?.last4}
                </MDTypography>
                <MDBox ml="auto" lineHeight={0} color={"dark"}>
                  <Tooltip
                    title="Set as default"
                    placement="top"
                    disableFocusListener={defaultPaymentMethod === payment?.id}
                    onClick={() => setDefaultPaymentMethod(payment?.id)}
                  >
                    <Icon sx={{ cursor: "pointer" }} fontSize="small">
                      {payment?.id === defaultPaymentMethod
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </Icon>
                  </Tooltip>
                </MDBox>
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Card>
  );
}

PaymentMethod.propTypes = {
  paymentMethods: PropTypes.any.isRequired,
  defaultPaymentMethod: PropTypes.string.isRequired,
};

export default PaymentMethod;
