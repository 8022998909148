import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";

import { useQuery } from "@tanstack/react-query";
import RivalzApi from "../../config/axios/RivalzApi";
import moment from "moment";

function Dashboard() {
  const { data: resDataDay } = useQuery({
    queryKey: ["chartDataDay"],
    queryFn: async () => RivalzApi.getChartData("DAY"),
  });

  const { data: resDataWeek } = useQuery({
    queryKey: ["chartDataWeek"],
    queryFn: async () => RivalzApi.getChartData("WEEK"),
  });

  const { data: resDataMonth } = useQuery({
    queryKey: ["chartDataMonth"],
    queryFn: async () => RivalzApi.getChartData("MONTH"),
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Files Uploaded (DAY)"
                  description={"Files uploaded per day"}
                  chart={{
                    labels: resDataDay?.labels?.map((data) => moment(data).format("DD-MM")),
                    datasets: { label: "Files Uploaded", data: resDataDay?.datasets?.[0]?.data },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Files Uploaded (Weekly)"
                  description={"Files uploaded this week"}
                  chart={{
                    labels: resDataWeek?.labels?.map((data) => moment(data).format("WW")),
                    datasets: { label: "Files Uploaded", data: resDataWeek?.datasets?.[0]?.data },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Files Uploaded (Monthly)"
                  description={"Files uploaded this month"}
                  chart={{
                    labels: resDataMonth?.labels?.map((data) => moment(data).format("MMM")),
                    datasets: { label: "Files Uploaded", data: resDataMonth?.datasets?.[0]?.data },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="secondary"
                  title="Bytes Uploaded (DAY)"
                  description={"Total bytes uploaded per day"}
                  chart={{
                    labels: resDataDay?.labels?.map((data) => moment(data).format("DD-MM")),
                    datasets: { label: "Files Uploaded", data: resDataDay?.datasets?.[1]?.data },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="secondary"
                  title="Bytes Uploaded (Weekly)"
                  description={"Total bytes uploaded week"}
                  chart={{
                    labels: resDataWeek?.labels?.map((data) => moment(data).format("WW")),
                    datasets: { label: "Files Uploaded", data: resDataWeek?.datasets?.[1]?.data },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="secondary"
                  title="Bytes Uploaded (Monthly)"
                  description={"Bytes uploaded this month"}
                  chart={{
                    labels: resDataMonth?.labels?.map((data) => moment(data).format("MMM")),
                    datasets: { label: "Files Uploaded", data: resDataMonth?.datasets?.[1]?.data },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
