import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import Card from "@mui/material/Card";
import useConnection from "../../../../config/zustand";

function CreditInfo() {
  const { creditInfo } = useConnection();

  return (
    <Card sx={{ boxShadow: "none", width: "100%" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Credit Info
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Credit id:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {creditInfo?._id}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Amount:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {creditInfo?.amount}$
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Amount paid:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {creditInfo?.amountPaid?.toFixed(2)}$
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={4}>
              <MDBox mt={0.5}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Expiry date:
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} md={8} xl={8}>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {moment(creditInfo?.expiryDate).format("DD/MM/YYYY")}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CreditInfo;
